.header {
    margin-left: 38px;
}
li{
    list-style-type: none;
}

.body{
    display: flex;
    align-items: center;
    height: 100vh;
}

/* .menu-container:hover{
color: grey;
cursor: pointer;
} */
