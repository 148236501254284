body {
    color: white;
    font-size: 14px;
    line-height: 1.5;
    /* border: 5px solid #777777; */
}

.TextWrap {
    margin: 10px;
}

img {
    margin:  -100px 0px 10px 100px;
    width: 55%;
    height: auto;
    padding: 10px;
    float: right;

}

.container {
    padding: 0px 50px;
    font-size: 22px;

}
.quote{
    margin-top: 100px;
    margin-left: -50px; 

}


p {
    color: black;
    text-align: left;
}
.ct{
    padding-top: 200px;
}

.QA{
    padding-top: -25%;
}