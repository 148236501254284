.bigi {
    position: relative;


}

.bigi {
    background-image: url("https://i.imgur.com/tav7dKA.jpg");
    background-position: center;
    background-size: cover;
    /* opacity: .5; */
}

.EF {
    height: 80vh;
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    margin-right: 50px;

}

.renform {
    opacity: 1;

}

.contact {
    padding-top: 15%;
    margin-top: 100px;
    color:white;
    font-size: large;
}

.top {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
}

